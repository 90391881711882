export enum appRoutes {
  ROOT = '',
  NOT_FOUND = 'not-found',
  DENIED = 'Denied.html',
  ERROR_PAGE = 'ErrorPage.html',
  WILDCARD_ROUTE = '**',
  DASHBOARD = 'web/dashboard.html',
  BARGE_LIST = 'web/bargelist.html',
  BARGE_DETAILS = 'web/bargeDetails.html',
  REPORT_CENTER = 'web/reports/reportcenter.html',
  CONDITIONS = 'web/conditions.html',
  MAP = 'web/map.html',
  BOAT_LIST = 'web/boatList.html',
  BOAT_DETAILS = 'web/boatdetails.html',
  SETTINGS = 'web/settings.html',
  SETTINGS_FORMS_ROOT = 'web/forms',
  SETTINGS_FILTERS = `${SETTINGS_FORMS_ROOT}/filters.html`,
  SETTINGS_ODPAIRS = `${SETTINGS_FORMS_ROOT}/ODPair.html`,
  SETTINGS_EMAIL = `${SETTINGS_FORMS_ROOT}/emails.html`,
  SETTINGS_FTP = `${SETTINGS_FORMS_ROOT}/FTP.html`,
  SETTINGS_SCHEDULER = `${SETTINGS_FORMS_ROOT}/scheduler.html`,
  SETTINGS_NOTIFICATIONS = `${SETTINGS_FORMS_ROOT}/notificationUP.html`,
  EDIT_REPORT = 'web/Reports.html',
  ADMIN_PAGE = 'web/admin/admin.html',
  ADMIN_ANNOUNCEMENTS = 'web/admin/announcements.html',
  ANNOUNCEMENTS = 'web/announcements.html',
  USER_ADMIN = 'web/admin/UserAdmin.html',
  NOTIFICATION_CP = 'web/admin/notificationCP.html',
  ADMIN_PROFILE = 'web/admin/TowlineProfile.html',
  SEARCH = 'web/search2.html',
  OXY_BARGE_REPORTS = 'web/BargeReport.html',
  MESSAGES = 'web/Messages.html',
  RESOURCES_PDF = 'resources/help.pdf',
}
